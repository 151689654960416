import _ from "lodash";
import {generateFetchFieldListAction} from "../../../../../apps/KpModule/actions/api";
import {hasDuplicates} from "../../utils/usefulFunctions";
import Errors from "../../../../utils/Errors";
import {translateName} from "../../../../../utils/i18n";

export const entities = [
    {
        name: 'Entity',
        facets: [
            {name: 'codeName', nameOptional: false, uniqueName: true}
        ],
        fields: [
            {path: 'entityType', type:'EntityConfiguration'},
            {
                path: 'attachments',
                type: 'StructureElement',
                link: 'MTM'
            },
            /*
            {path: 'attachmentsCombination', type: 'EntityAttachmentsRules'},
            {
                path: 'entityAttachments',
                type: 'EntityAttachment',
                link: {
                    type: "OTM",
                    onParent: true,
                    onChild: false,
                }
            }

             */
        ],
        filters: [
            {
                name: 'byEntityType',
                tKey: 'entityType',
                isDefault: false,
                client: true,
                path: 'entityType',
                object: 'EntityConfiguration',
                display: "name",    //This fucking line is obligatory apparently
                translateName: true,
                clearable: false,
                query: function(context) {
                    const entityTypeID = _.get(context, 'data.entityType.id')
                    return entityTypeID ? {"entityType": {$eq: global.ObjectID(entityTypeID)}} : {_id: null}
                }
            }
        ],
        beforeSave: function (newObject, oldObject, context, callback) {
            const attachedToThisAxesIds = newObject.attachments.map(obj => global.ObjectID(obj.structureAxis.id).toString())
            const respectAttachmentsRules = newObject.entityType.entityAttachmentsRules.length === 0
                || newObject.entityType.entityAttachmentsRules.some(rule => {
                        const axesPossibleCombinationIds = rule.axesPossibleCombination.map(obj => global.ObjectID(obj.id).toString())
                        return axesPossibleCombinationIds.every(obligatoryAxesId => attachedToThisAxesIds.includes(obligatoryAxesId))
                   })
            if (!respectAttachmentsRules){
                callback(new Errors.ValidationError('This entity does not respect attachments rules'))
            }
            else {callback(null, newObject, oldObject)}
        }
    },
    /*
    {
        name: 'EntityAttachment',
        type: 'mongoInternal',
        fields: [
            {path: 'axis', type: 'StructureAxes'},
            {path: 'elementOfThisAxis', type: 'StructureElement'},
        ]
    }

     */
]

export const module_ = {
    object: 'Entity',
    tKey: 'elementaryEntity',
    category: {
        path: 'structure',
        icon: 'layers'
    },
    viewMap: {
        dt: [
            {path: 'code'},
            {path: 'name', type: 'translatedText'},
            {path: 'entityType', translateName: true},
            {path: 'attachments', translateName: true}
        ],
        form: {
            fields: [
                {
                    path: 'entityType',
                    translateName: true,
                    fieldPath: ['id', 'name',
                        //'structures',
                        'entityAttachmentsRules'],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){

                            const attachmentsCombinationField = module.viewMap.form.fields.find( field => field.path === 'attachmentsCombination')
                            const axesPossibleCombinationIds = (newValue?.entityAttachmentsRules || []).flatMap(rule => {
                                return rule.axesPossibleCombination.map(obj => obj.id)
                            })

                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-entity.Entity_attachments",
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            obligatoryAxesIds: axesPossibleCombinationIds
                                        }
                                    }
                                )
                            )

                            /*
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-entity.Entity_attachmentsCombination",
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            entityTypeID: newValue?.id
                                        }
                                    }
                                )
                            ).then( (response) => {
                                const firstCombination = !!response.data.length ? response.data[0] : null
                                attachmentsCombinationField.setValue(firstCombination)
                            })

                             */
                        }
                    }
                },
                {path: 'code'},
                {path: 'name', type: 'textarea', unique: true, placeholder: 'fr:: nom en français\nen:: name in english\n...'},
                /*
                {
                    path: 'attachmentsCombination',
                    tKey: 'possibleCombinations',
                    clearable: false,
                    display: 'name',
                    fieldPath: ['id', 'name', 'axesPossibleCombination'],
                    filters: ['byEntityType'],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const state = store.getState()
                            const language = _.get(state, 'ui.language')
                            console.log("newValue", newValue)
                            const entityAttachmentsField = module.viewMap.form.fields.find( field => field.path === 'entityAttachments')
                            const result = (newValue?.axesPossibleCombination || []).map(axis => {
                                return {
                                    axis: {id: axis.id, name: translateName(axis.name, language)},
                                    elementOfThisAxis: null
                                }
                            })
                            console.log("result", result)
                            entityAttachmentsField.setValue(result)
                        }
                    }
                },
                {
                    path: 'entityAttachments',
                    tKey: 'attachmentPerAxis',
                    type: 'dtObjects',
                    fields: [
                        {
                            path: "axis",
                            fieldPath: ['id', 'name', 'code'],
                        },
                        {
                            path: "elementOfThisAxis",
                            tKey: 'attachment',
                            type: 'editText'
                            //type: 'datePicker'
                            //type: "idList", list: [{id: '4', name: 'option1'}]
                            //
                            // 'dtObjectsMultiselect'
                            //type: "DtObjectsColumn"
                            //type: 'datePicker'
                        },
                    ],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            console.log("entityAttachments", newValue)
                        }
                    }
                },

                 */
                {
                    path: 'attachments',
                    display: 'nameWithStructureAndAxis',
                    filters: ['eliminateSelectedAndIncoherentOptions'],
                    fieldPath: [ 'id', 'nameWithStructureAndAxis', 'structureAxis', 'structure'],
                    subscriptions: {
                        onChange (newValue, oldValue, {module, store}){
                            const entityTypeField = module.viewMap.form.fields.find( field => field.path === 'entityType')
                            const entityType = entityTypeField && entityTypeField.getValue()
                            const axesPossibleCombinationIds = (entityType?.entityAttachmentsRules || []).flatMap(rule => {
                                return rule.axesPossibleCombination.map(obj => obj.id)
                            })
                            store.dispatch(
                                generateFetchFieldListAction(
                                    "m-SE-entity.Entity_attachments",
                                    store.getState,
                                    'form',
                                    {
                                        data: {
                                            //structures: entityType?.structures,
                                            obligatoryAxesIds: axesPossibleCombinationIds,
                                            selectedElements: newValue
                                        }
                                    }
                                )
                            )
                        }
                    }
                }
            ],
        }
    },
    filters: ['byEntityType']
}
