import _ from "lodash"
import {basicContext} from "../../../../utils/contextUtils"
import {getEdfinWorkflowNotificationData} from "../../../../utils/notificationData";
const { generateFetchFieldListAction } = require('../../../../../apps/KpModule/actions/api')


async function findBusinessProjects(context) {
    const typeOfOfferId = _.get(context, 'data.typeOfOffer.id')
    if(!typeOfOfferId) return []

    return await global.app.I.BusinessProject.find({
        ...basicContext(context),
        query: {
            'typeOfOffer': new global.ObjectID(typeOfOfferId),
            'workflow.type': 'inProgress',
        },
        fieldPath: ['id', 'fullName', 'country.id', 'beginYear.name', 'currentFunctions', 'file']
    })
}
async function getBusinessProject(businessProjectId, context) {

    const businessProject = await global.app.I.BusinessProject.get(businessProjectId, {
        ...basicContext(context),
        fieldPath: [
            'id', 'name', 'fullName', 'beginYear.name', 'country.id', 'currentFunctions', 'comments', 'workflow.step', 'workflow.order'
        ]
    })

    const delegations =  await global.app.I.StaticWorkflow.find({
        ...basicContext(context),
        query: {
            businessProject: new global.ObjectID(businessProject.id),
            step: _.get(businessProject, 'workflow.step'),
            order: _.get(businessProject, 'workflow.order'),
        },
        fieldPath: [
            'teamMember.id', 'teamMember.userName',
        ]
    })

    return {
        ...businessProject,
        delegatedUsers: delegations.map(delegation => delegation.teamMember)
    }


}

export async function saveDelegationObjects(businessProject, context) {

    await global.app.I.StaticWorkflow.collection.deleteMany({businessProject: new global.ObjectID(businessProject.id)})

    const delegations = businessProject.delegatedUsers.map(teamMember => ({
        businessProject: new global.ObjectID(businessProject.id),
        step: _.get(businessProject, 'workflow.step'),
        order: _.get(businessProject, 'workflow.order'),
        teamMember: new global.ObjectID(teamMember.id),
        group: new global.ObjectID(context.group.id)
    }))

    if(!!delegations.length) {
        await global.app.I.StaticWorkflow.collection.insertMany(delegations)
    }
}

export const delegationEntity = {
    name: 'Delegation',
    type: null,
    facets: [
        'comments',
        {name: 'files', linkType: 'OTO'}
    ],
    fields: [
        'name',
        'fullName',
        {type: 'Country'},
        {path: 'currentFunctions'},
        {path: 'beginYear', type: 'Year'},
        {path: 'delegatedUsers', type: 'TeamMember', link: 'MTM', nullable: true},
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L')
            .then(context => findBusinessProjects(context))
            .then(objects => callback(null, objects))
            .catch(error => callback(error))
    },
    get: function(id, context, callback) {
        this.prepareContext(context, 'R')
            .then(context => getBusinessProject(id, context))
            .then(object => callback(null, object))
            .catch(error => callback(error))
    },
    save: function(newObject, context, callback) {
        this.prepareContext(context, 'S')
            .then(context => saveDelegationObjects(newObject, context))
            .then(() => {
                getEdfinWorkflowNotificationData(context.group.id).then(
                    data => global.ioSocket.emit('afterSave', data)
                )
                callback(null, ({
                    ...newObject,
                    beginYear: global.app.I.Year.objects.find(
                        year => year.id === newObject.beginYear.id
                    )
                }))
            })
            .catch(error => callback(error))
    }
}

export const adminDelegation = {
    name: "adminDelegation",
    object: "Delegation",
    category: {
        path: "configuration",
        icon: 'settings'
    },
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: "country", width: 200},
            {path: "fullName", tKey: 'name', width: 500},
            {path: "beginYear", width: 100},

            {path: "currentFunctions", width: 100},
            {path: "file", tKey: "FA"}
        ],
        form: {
            fields: [
                {
                    path: "country", tKey: "country", display: "fullInformations", writable: false,
                    filters: ["inUserTeam"]
                },
                {path: "name", writable: false},

                {path: "workflow.step", tKey: "step", writable: false},
                {path: "workflow.order", tKey: "order", writable: false},
                {path: "comments", tKey: "comment_plural", editeable: false},

                {path:"delegatedUsers", filters:["byCountry"], display: "userName"},
                {path: "beginYear", fieldPath: ["id", "name"], hidden: true},
                {path: "businessProject", hidden: true}
            ],
            onOpen: ({state, store}) => {
                const projectCountry = {country: state.edit.object.data.country}

                store.dispatch(
                    generateFetchFieldListAction(
                        "m-I-adminDelegation.Delegation_delegatedUsers",
                        store.getState,
                        'form',
                        {data: projectCountry}
                    )
                )
            }
        }
    },
    filters: [
        {
            name: "byTypeOfOffer",
            path: "typeOfOffer",
            object: "TypeOfOffer",
            display: "nameWithRange",
            width: 12,
            isDefault: false,
            default: {id: null},
            client: true,
        }
    ]
}
